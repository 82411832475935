import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { initCookieConsent } from "./components/UI/Cookies";
import { QuizProvider } from "./hooks/useQuiz";
import Home from "./routes/Home";
import Quiz from "./routes/Quiz";

const App = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        initCookieConsent();
    });

    if (loading) return <></>
    else return (
        <QuizProvider>
            <Routes>
                <Route exact path="/" element={<Home/>} />
                <Route exact path="/:name" element={<Quiz/>} />
            </Routes>
            <footer>Copyright © 2024 Platforma VIZE 0</footer>
        </QuizProvider>
    );
};

export default App;
