import { useQuiz } from "../hooks/useQuiz"
import { showCookieConsent } from "../components/UI/Cookies";
import ruka from "../assets/Ruka-mobil-bez-svetel-e-learning.png";
import logo from "../assets/logo.webp"
import Item from "../components/UI/Item";
import Loading from "../components/UI/Loading";

const Home = () => {
    const {allQuizes, getUnseen} = useQuiz();

    const showConsent = () => {
        showCookieConsent();
    }

    return (
        <div className="home">
            <div className="home__intro">
                <div className="home__intro__text">
                    <h1>Vyzkoušej hru na hraně reality
                        <b> pro bezpečné silnice</b>
                    </h1>
                    <p>Na silnicích se každou chvíli odehrávají situace, které potřebují okamžitou reakci, jak řidičů, tak chodců. <b>Pozdní nebo špatná reakce může způsobit dopravní nehodu.</b></p>
                    <p><b>Zahraj si interaktivní hru a otestuj své reakce</b> a dopravní znalosti. </p>
                    <p>Zvládneš správně zareagovat na všechny situace?</p>
                </div>
                <div className="home__intro__img">
                    <img src={ruka} alt='ruka držící telefon' />
                </div>
                <a className="logo" target="_blank" href="https://nehodananecisto.cz/"><img src={logo} /></a>
            </div>
            <div className="home__box">
                <div className="perex">
                    <div className="perex__title">
                        <b>E-learning</b>
                        <h2>Jdeme na to!</h2>
                    </div>
                    <p>Z pohledu chodce nebo řidiče si <b>vyzkoušej situace</b>, které se <b>opravdu staly na území ČR</b>. Vyzkoušej si je všechny zde v interaktivní hře. 
                       <br/><br/> <b>A jak na to?</b> Na začátku hry se ti zobrazí video s dopravní situací a následně budeš mít na výběr ze tří možností, které rozhodnou o průběhu situace. Otestuj své reakce nanečisto a zjisti co se stane.</p>
                </div>
                {allQuizes?.items ? <div className="home__box__container">{allQuizes?.items?.map(q=> <Item data={q} /> )}</div> : <div style={{width:"100%", display:'flex', padding:'4rem 5% 2rem'}}><Loading /></div> }
            </div>
            <div className="home__events">
                <strong>UDÁLOSTI</strong>
                <h2>POJĎ SI VYZKOUŠET<br/>VR V REÁLU</h2>
                <p>Hru máš za sebou, ale chce to něco víc? Přijď si nasadit VR brýle do našeho stánku na jednu z plánovaných akcí a zažij Nehodu nanečisto na vlastní kůži.</p>
                <a href="https://nehodananecisto.cz/terminy">Zobrazit události</a>
            </div>
        </div>
    )
}

export default Home