import axios from "axios";
import { APIURL } from "..";
import { createContext, useContext } from "react";
import { useToast } from "../components/Toast/useToast";

const APIContext = createContext();
export const APIProvider = ({ children }) => {
    const {addToast} = useToast();
    const apiCall = async (method, endpoint, data, notification=false) =>{
        try {
            let config = {
                method: method,
                url: APIURL+endpoint,
            }
            if (method==='post') {
                config.data = JSON.stringify(data);
                config.headers = {'Content-Type': 'application/json'}
            } 
            const response = await axios(config)
            if (notification) {
                addToast({type:'success', message:response.data.message||endpoint})
            }
            return response.data.body
        }
        catch (error) {
            console.error(error.response.data.body);
            if (notification) {
                addToast({type:'error', message:error.response.data.body||endpoint})
            } 
            // throw error.response.data.body;
        }
    } 
    
    const post = async (endpoint, data, notification) => apiCall('post', endpoint, data, notification);
    const get = async (endpoint, data, notification) => apiCall('get', endpoint, data, notification);

    return <APIContext.Provider value={{post, get}}>{children}</APIContext.Provider>;
}

export const useAPI = () => {
    return useContext(APIContext);
}

//TODO!! Opravit vrácení erroru, nevrací chybu při použítí id=0. Vždycky vrací soubor, který je prázdný
export const downloadFormAnswers = async (id, event) => {
    try {
        const response = await axios.post(`${APIURL}/admin/form/exportAnswers`, {id: id, event_id: event}, { headers: { Accept: 'application/json' }, responseType: 'blob' });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'answers.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return "Úspěšně staženo"
    } catch (error) {
        console.error(error.response.data.body);
        throw error.response.data.body;
    }
}