import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./styles/index.scss";
import { IconContext } from '@phosphor-icons/react';
import { ToastProvider } from './components/Toast/useToast';
import { APIProvider } from './hooks/useAPI';
import { BrowserRouter } from 'react-router-dom';

const host = window.location.host;
const http = window.location.protocol;
let a = host;
if(host.includes('localhost'))  a = '127.0.0.1:8000';
if(host.includes('devel'))  a = 'pvvr.tw-planner.com';
if(host.includes('nehodananecisto'))  a = 'nehodananecisto.cz';
export const APIURL = http+"//"+a+"/api";
export const ROOTURL = http+"//"+a+'/';
export const URL = http+"//localhost:3000";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
      <ToastProvider>
        <APIProvider>
          <IconContext.Provider value={{size:'1.5rem'}}>
            <BrowserRouter>
              <App />  
            </BrowserRouter>
          </IconContext.Provider>
        </APIProvider>
      </ToastProvider>
);