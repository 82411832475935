import { createContext, useContext, useEffect, useState } from "react";
import { useAPI } from "./useAPI";
import { useNavigate } from "react-router-dom";

const QuizContext = createContext();
export const QuizProvider = ({ children }) => {
    const {get, post} = useAPI();
    const [allQuizes, setAllQuizes] = useState([]);
    const [currentQuiz, setCurrentQuiz] = useState(null);
    const [answers, setAnswers] = useState([]);
    const navigate = useNavigate();

    const fetch = async () => {
        await post('/collection/items', {type:'quiz', orderAsc: "ASC", limit: 20, page:1}).then((response=>{
            setAllQuizes(response);
        }))
    }
    const openQuiz = async (id) => {
        setCurrentQuiz(null);
        await get('/collection/item/'+id).then((response=>{
            setCurrentQuiz(response.item)
        }))
    }

    const closeQuiz = () => {
        setCurrentQuiz(null);
        navigate("/")
    }

    const saveAnswer = (data) => {
        setAnswers(prevAnsw => {
            prevAnsw.find(a=>a.question==data.question) ? (prevAnsw.find(a=>a.question==data.question).answer = data.answer) :  (prevAnsw.push(data));
            return prevAnsw
        })
        localStorage.setItem("answers", JSON.stringify(answers));
    }
    const getLocalStorage = () => {
        setAnswers(JSON.parse(localStorage.getItem('answers')||"[]"))
    }

    useEffect(()=>{
        fetch();
        getLocalStorage();
    }, [])

    const getUnseen = (id) => {
        if (id) return answers.find(a => a.question==id)
        else return allQuizes.items.filter(q=>!answers.find(a => a.question==q.id))
    }

    return <QuizContext.Provider value={{allQuizes, currentQuiz, openQuiz, saveAnswer, getUnseen, closeQuiz}}>{children}</QuizContext.Provider>;
}

export const useQuiz = () => {
    return useContext(QuizContext);
}